@import 'base/reset';
@import 'base/fonts';
@import 'keyframes';

// variables
$color_theme: #FF5C00;
// END variables

body {
  font: 400 16px GothamPro, sans-serif;
  line-height: 1.4;
  color:#fff;
}
button,path,circle,input[submit] {
  transition: .2s;
}
input {
  font: 400 16px GothamPro, sans-serif;
}
button,input[type="submit"] {
  font: 400 14px GothamPro, sans-serif;
  cursor: pointer;
  background-color: transparent;
  border: none;
}
button,input[type="submit"],a {
  &:hover {
    transition: .2s;
  }

}
path,rect {
  transition: .2s;
}
input[type="submit"] {
  -webkit-appearance: none;
}
input,textarea {
  border-radius: 0;
}
// =======





body {
  background: url(../images/bg-body.jpg);
  background-attachment: fixed;
  transition: .3s;
}

.text-center {
  text-align: center;
}

.container {
  width: 90vw;
  margin: 0 auto;
}
.wrapper {
  opacity: 0;
  transition: .4s;
}

.first-window,.first-window-portfolio,.first-window-project,.first-window-about-us,.first-window-contact {
  position: relative;
  height: 90vh;
  margin-top: 5vh;
  width: 100%;
  overflow: hidden;
  padding: 40px;
  transition: .4s;
  .list-slides {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .item-slide {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        
      }
    }
  }
  .dots {
    position: absolute;
    right: 60px;
    bottom: 60px;
    z-index: 9;
    .slick-dots {
      position: relative;
      li {
        background: transparent;
        border: 3px solid #fff;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        &.slick-active {
          border-color: $color_theme;
          background-color: $color_theme;
        }
        button:before {
          content: none;
        }
      }
    }
  }
  .figures-wrap {
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    background: rgba(0,0,0,.2);
    transition: .1s;
    .elem-figure {
      width: 100%;
      height: 100%;
      transition: .2s;
      img {
        width: 100%;
        height: 100%;
        opacity: .8;
        animation: 4s linear 0s infinite alternate dreamSpot;
        transition: .2s;
      }
    }
    .ellipse-line-wrap {
      position: absolute;
      z-index: 2;
      top: 10%;
      left: 60%;
      width: 0%;
      height: 65%;
      overflow: hidden;
      transition: 1.5s;

      .ellipse-line {
        width: 60vw;
        height: 200%;
        position: absolute;
        border-left: 1px solid #414141;
        border-top: 1px solid #414141;
        border-radius: 50%;
      }
    }
  }
  .main-menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    a {
      color: #F0F0F0;
      display: inline-flex;
      align-items: center;
      position: absolute;
      transition: .2s;
      &:first-child {
        left: 11%;
        bottom: 54%;
      }
      &:nth-child(2) {
        left: 24%;
        bottom: 74.5%;
      }
      &:last-child {
        left: 39.5%;
        bottom: 89%;
      }


      span.dot {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: 2px solid #6A6A6A;
        margin-right: 5px;
        vertical-align: sub;
        transition: .4s;
        transform: scale(0);
        opacity: 0;
        background-color: #111;
      }
      span.name {
        transition: .4s;
        opacity: 0;
      }
      &:hover {
        color: $color_theme;
        span.dot {
          background-color: $color_theme;
          border-color: $color_theme;
        }
      }
    }
    
  }
  .lamps {
    cursor: pointer;
    transition: .2s;
    position: absolute;
    top: 0;
    left: 5%;
    z-index: 9;
    width: 400px;
    height: 300px;
    .lamp-1,.lamp-2 {
      position: absolute;
      top: 0;
      z-index: 3;
    }
    .lamp-1 {
      left: 2%;
    }
    .lamp-2 {
      left: 35%;
    }
    &:hover {
      opacity: .8;
    }
  }

  
  
  .social-list {
    display: flex;
    position: absolute;
    bottom: 40px;
    left: 50px;
    column-gap: 50px;
    z-index: 9;
    circle {
      transition: .2s;
    }
    a {
      &:hover {
        circle {
          fill: $color_theme;
        }
        path {
          fill: #fff;
        }
      }
    }
  }

  .logo {
    position: absolute;
    top: calc(50% - 130px);
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 215px;
    z-index: 10;
    transition: .2s;
  }
}
.dots-wrapper {
  position: absolute;
  right: 60px;
  z-index: 9;
  bottom: 20px;
  list-style: none;
  display: flex;
  column-gap: 10px;
  button {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: 2px solid #fff;
    display: block;
    padding: 0;
    &:hover {
      border-color: $color_theme;
    }
  }
  li {
    &.active {
      button {
        background-color: $color_theme;
        border-color: $color_theme;
      }
    }
  }
}

.home-first-window-wrapper {
  width: 100%;
  .first-window {
    margin-top: 0;
    height: 100vh;
  }
}

.first-window-project {
  .switch-theme {
    left: auto;
    right: 20%;
  }
}
input {
  &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px #171717;
    -webkit-text-fill-color: #fff;
  }
}

body.light {
  background-image: url(../images/bg-body-light.jpg);
  .first-window {
    .figures-wrap {
      .elem-figure {
        filter: invert(1);
      }
      
    }
    .logo {
      filter: invert(1);
    }
    .main-menu {
      a {
        span {
          &.name {
            color: #000;
          }
        }
        &:hover {
          span {
            &.name {
              color: $color_theme;
            }
          }
        }
      }
    }
  }
  .title-container {
    filter: invert(1);
  }
  .switch-theme {
    background: radial-gradient(circle 87px at 45% 54%,#ffec97,transparent);
    animation: 2s linear 0s infinite alternate switchAnimatiion;
  }

  .about-us-section .about-us-wrap {
    .top-text {
      filter: invert(1);
    }
  }
  .statistics-section {
    .item-statistic {
      .count,.value {
        filter: invert(1);
      }
    }
  }
  .advantages-section {
    .advantage-desc {
      filter: invert(1);
    }
    .list-items-advantage .item-advantage .title {
      filter: invert(1);
    }
  }
  .slider-reviews-wrapper .slick-next, .slider-reviews-wrapper .slick-prev {
    filter: invert(1);
  }
  .contact-section .content-wrap .text {
    color: #1A1A1A;
  }
  .input-style input:not([type=submit]), .input-style textarea {
    color: #171717;
  }
  .slider-reviews-wrapper .item-slider-wrap .name {
    color: #171717;
  }
  .map-container .slice-deco,.advantages-section .deco, .advantages-section .deco-bottom-right, .advantages-section .deco-top-left {
    filter: invert(1);
  }
  .list-portfolio {
    .portfolio-item {
      border: none;
      .name-project {
        color: #171717;
      }
      .elem-slice {
        filter: invert(1);
      }
    }
  }
  .portfolio-section {
    .link-more-wrap .link-more {
      path {
        stroke: $color_theme;
      }
      &:hover {
        path {
          stroke: #fff;
        }
      }
    }
  }
  .slider-reviews-wrapper {
    .dots .slick-dots li {
      border-color: #171717;
    }
    .item-slider-wrap {
      .text {
        color: #171717;
      }
    }
  }
  .first-window-contact {
    .logo-header {
      filter: invert(1);
    }
    .text-contact-wrap {
      img {
        filter: invert(1);
      }
    }
    .contact-list {
      .item-contact {
        background: transparent;
        filter: invert(1);
      }
    }
    .custom-map {
      .slice-map {
        filter: invert(1);
      }
    }
  }
  .first-window-about-us {
    .logo-header {
      filter: invert(1);
    }
    
    .content-about-us {
      .img-wrap {
        img {
          filter: invert(1);
        }
      }
    }
    .text-about-us-wrap {
      img {
        filter: invert(1);
      }
    }
    .text-wrap {
      color: #171717;
    }
    .advant-list {
      .title {
        color: #171717;
      }
    }
  }
  .first-window-about-us,.first-window-contact {
    .social-list {
      circle {
        fill: $color_theme;
      }
      path {
        fill: #fff;
      }
    }
  }
  
  .our-production-section {
    .subtext-col .title,.text-col .text {
      color: #171717;
    }
  }
  .reviews-section .text-content .text {
    color: #171717;
    a {
      color: $color_theme;
    }
  }
  .contact-section {
    .title-contact-container {
      filter: invert(1);
    }
  }
  footer {
    .logo {
      filter: invert(1);
    }
    .social-list {
      a {
        color: #171717;
      }
    }
  }
}

.first-window-contact {
  min-height: 90vh;
  height: auto;
  #custom-map {
    top: 0;
  }
  .switch-theme {
    left: auto;
    right: 19%;
  }
  .custom-map {
    position: absolute;
    top: 0;
    right: 0;
    width: 45%;
    height: calc(100% - 50px);
    overflow: hidden;
    .slice-map {
      position: absolute;
      left: -40%;
      top: 0;
      z-index: 2;
      transition: .4s;
    }
    .text-map {
      position: absolute;
      right: 60px;
      bottom: 60px;
      color: #f0f0f0;
      font-size: 16px;
      width: 330px;
      z-index: 2;
    }
  }
  .input-style span.label {
    font-size: 12px;
  }
  .contact-list-wrap {
    margin-top: 60px;
  }
  .contact-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    .item-contact {
      width: auto;
      padding: 0;
      background: transparent;
    }
  }
}

header.header {
  padding-right: 30px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 9;
  .social-list {
    position: static;
  }
  .main-menu-top {
    display: flex;
    column-gap: 55px;
    margin-left: -10%;
    a {
      color: #848484;
      font-weight: 700;
      &:hover {
        color: #F0F0F0;
      }
    }
  }
}

.first-window-about-us {
  height: auto;
  min-height: 90vh;
  .content-about-us {
    padding: 30px 0;
    display: flex;
    padding-right: 10%;
    align-items: flex-end;
    background: radial-gradient(circle at center, rgba(255,255,255,.03) 0, transparent 43%);
    .img-wrap {
      width: 45%;
      padding-right: 10%;
    }
    .text-about-us-wrap {
      width: 55%;
    }
    .main-text {
      font-size: 24px;
      line-height: 1.8;
      margin-top: 15px;
    }
  }
  .line {
    margin-top: 25px;
    height: 1px;
    background-color: #6a6a6a;
    transition: 2s;
    width: 100%;
  }
  .advant-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    margin-top: 10%;
    .title {
      font-size: 12px;
      font-weight: 700;
      color: #f0f0f0;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    .text {
      color: #6A6A6A;
      font-size: 14px;
      line-height: 1.8;
    }
  }
}

.text-portfolio-wrap {
  position: absolute;
  top: 25%;
  left: 20%;
  width: 30%;
  z-index: 3;
  text-align: justify;
  .text {
    width: 60%;
    color: #F0F0F0;
    min-width: 320px;
    margin: 15px 0;
  }
  .subtext {
    font-size: 12px;
    width: 60%;
    line-height: 1.8;
    color: #6A6A6A;
    min-width: 320px;
  }
}
.text-project-wrap {
  position: absolute;
  top: 25%;
  left: 15%;
  width: 75%;
  z-index: 3;
  text-align: justify;
  .text-wrap {
    display: flex;
    justify-content: flex-end;
    padding-top: 80px;
    position: relative;
    .line {
      position: absolute;
      top: 40px;
      left: 0;
      height: 1px;
      background-color: #6a6a6a;
      transition: 2s;
      width: 0;
    }
  }
  .text {
    width: 50%;
    color: #F0F0F0;
    min-width: 320px;
    margin: 15px 0;
    font-size: 24px;
  }
}
.first-window-portfolio {
  .glider-track {
    height: 100%;
  }
  .list-slides {
    .slick-list,.slick-track,.slick-slide {
      height: 100%;
    }
    .item-slide {
      img {
        object-fit: fill;
      }
      
    }
  }
  .list-slides-elems {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 8;
    top: 0;
    left: 0;
    
    .slick-list, .slick-track {
      height: 100%;
    }
    .item-slide {
      display: flex;
      align-items: flex-end;
      height: 100%;
    }
    .slide-elem-1,.slide-elem-2,.slide-elem-3 {
      transition: .7s;
    }
    .slide-elem-1 {
      transform: translateX(-50%);
    }
    .slide-elem-2 {
      margin-left: 13vw;
      transform: translateX(50%);
    }
    .slide-elem-3 {
      position: absolute;
      top: 0;
      right: 5vw;
      transform: translateY(-10%);
    }
    .slick-current {
      .slide-elem-1 {
        transform: translateX(0);
      }
      .slide-elem-2 {
        transform: translateX(0);
      }
      .slide-elem-3 {
        transform: translatey(0);
      }
    }
  }
  .text-portfolio-wrap {
    img {
      transform: translateY(-60px);
      transition: .4s;
    }
    .text,.subtext {
      transform: translateY(60px);
      transition: .4s;
    }
  }
  .slick-current {
    .text-portfolio-wrap {
      img {
        transform: translateY(0);
      }
      .text,.subtext {
        transform: translateY(0);
      }
    }
  }
}
.list-files {
  padding-top: 40px;
  .item-file {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
    padding: 3px 0;
    .ico {
      width: 25px;
    }
  }
}

// .glider-track {
//   transform: translate3d(0,0,0)!important;
// }
// .glider-slide {
//   opacity: 0;
//   z-index: 0;
//   position: absolute;
//   top: 0;
//   left: 0;
//   transition: opacity .4s ease;
// }
  
// .glider-slide.active {
//   opacity: 1;
//   z-index: 1;
// }
.projects-sliders {
  padding-top: 115px;
  margin-bottom: 115px;
  position: relative;
  .projects-sliders-list {
    height: 100vh;
  }
  .slick-list,.slick-track {
    height: 100%;
  }
  .item-slide {
    height: 100%;
    position: relative;
    &.visible {
      
    }
  }
  .slide-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .btn-prev,.btn-next {
    position: absolute;
    top: calc(50% - 32px);
    width: 64px;
    height: 64px;
    border: none;
    border-radius: 50%;
    background: rgba(0,0,0,.2);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
    z-index: 9;
    &:hover {
      background-color: $color_theme;
      transform: scale(1.07);
    }
  }
  .glider-track {
    height: 100%;
  }
  .btn-prev {
    left: 40px;
  }
  .btn-next {
    right: 40px;
  }
  .info-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 250px 0 20px;
    background-color: #1a1a1a;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    background: linear-gradient(180deg, rgba(26, 26, 26, 0) 0%, #1A1A1A 75.66%);
    transition: .8s;
    .title {
      color: #f0f0f0;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .item-info {
      padding: 0 20%;
      position: relative;
      &:after {
        content: '';
        display: block;
        height: 16px;
        width: 1px;
        position: absolute;
        right: 0;
        top: calc(50% - 8px);
        background-color: #848484;
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
    .text {
      font-size: 16px;
      color: #fff;
    }
  }
}

.list-portfolio {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 300px;
  column-gap: 7px;
  row-gap: 7px;
  .portfolio-item {
    border: 1px solid #323232;
    position: relative;
    overflow: hidden;
    transition: .2s;
    &:first-child {
      grid-row: 1/3;
    }
    &:nth-child(2) {
      grid-row: 1/3;
    }
    &:nth-child(3) {
      grid-row: 1/3;
      grid-column: 3/5;
    }
    &:nth-child(4) {
      grid-row: 3/5;
      grid-column: 1/3;
    }
    &:nth-child(5) {
      grid-column: 3/5;
    }
    &:nth-child(6) {
      grid-column: 3/5;
    }
    &:hover {
      border-color: $color_theme;
      .thumb {
        transform: scale(1.1);
      }
      .elem-slice {
        height: 160px;
      }
    }
    .thumb {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 4s;
    }
    .elem-slice {
      position: absolute;
      left: 0;
      bottom: 0;
      transition: 1s;
      height: 150px;
      min-width: 100%;
    }
    .info-project {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      padding: 15px 25px;
    }
    .name-project {
      font-size: 24px;
      color: #fff;
      font-weight: 700;
      margin-bottom: 7px;
    }
    .link-more {
      color: $color_theme;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 700;
      &:hover {
        color: #fff;
      }
    }
  }
}


.title-container {
  font: normal 96px 'New York';
  color: #fff;
  margin: 40px 0 30px;
  transition: .3s;
}

.deco-line-circle {
  position: relative;
  display: flex;
  align-items: center;
  .line {
    width: 1px;
    height: calc(100% - 16px);
    background-color: #313131;
  }
  .circle {
    width: 16px;
    height: 16px;
    border: 1px solid #313131;
    border-radius: 50%;
  }
}
.our-projects-sectiion {
  padding-top: 140px;
  position: relative;
  .deco-line-circle {
    position: absolute;
    top: 5vh;
    left: calc(50% - 8px);
    height: 120px;
    flex-direction: column;
  }
  .container {
    position: relative;
  }
  .deco-line {
    position: absolute;
    right: 0;
    top: calc(-180px + 5vh);
    width: 1px;
    height: 120px;
    background-color: #313131;
  }
}
.list-gallery {
  display: grid;
  column-gap: 7px;
  row-gap: 7px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 250px;
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 8s;
  }
  .item-gallery {
    &:first-child {
      grid-row: 1/3;
    }
    &:nth-child(2) {
      grid-row: 1/3;
    }
    &:nth-child(3) {
      grid-row: 1/3;
      grid-column: 3/5;
    }
    &:nth-child(4) {
      grid-row: 3/6;
      grid-column: 1/3;
    }
    &:nth-child(5),&:nth-child(6),&:nth-child(7) {
      grid-column: 3/5;
    }
    
  }
  .item-image {
    position: relative;
    overflow: hidden;
    
    .layer-tile {
      width: calc(100% - 60px);
      height: calc(100% - 60px);
      position: absolute;
      padding: 30px;
      top: 30px;
      left: 30px;
      opacity: 0;
      display: flex;
      align-items: flex-end;
      //background-image: radial-gradient(circle, #fff, #ff00 300px);
      background-image: radial-gradient(circle, transparent, rgba(0,0,0,.5) 300px);
      background-position: var(--x, 0px) var(--y, 0px);
      background-repeat: no-repeat;
      background-size: 200% 200%;
      .name-project {
        color: #fff;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 3px;
        margin-left: 50px;
        margin-bottom: -40px;
        transition: 1s;
        display: inline-block;
        opacity: 0;
      }
    }
    &:hover {
      
      .image {
        transform: scale(1.1);
      }
      .layer-tile {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 1;
        z-index: 1;
        .name-project {
          margin-left: 0;
          margin-bottom: 0;
          opacity: 1;
        }
      }
    }
  }
  .link-more {
    height: 100%;
    position: relative;
    border: 1px solid $color_theme;
    font-size: 24px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color_theme;
    text-transform: uppercase;
    background-color: #1E1D1D;
    span {
      display: inline-block;
      padding: 10px 0;
      border-bottom: 2px solid $color_theme;
      letter-spacing: 1px;
      font-weight: 700;
      transition: .5s;
      position: relative;
      z-index: 2;
    }
    &:hover {
      color: #fff;
      &:before {
        height: 100%;
      }
    }
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      background-color: $color_theme;
      position: absolute;
      left: 0;
      transition: .5s;
      z-index: 1;
    }
  }
}


.about-us-section {
  padding: 50px 0 170px;
  position: relative;
  .deco-line-left,.deco-line-center {
    position: absolute;
    width: 1px;
    background-color: #313131;
  }
  .deco-line-left {
    height: calc(100% - 170px);
    top: 0;
    left: 0;
  }
  .deco-line-center {
    left: 50%;
    top: 50px;
    height: 50%;
  }
  .lamp-top {
    position: absolute;
    top: 0;
    left: 20%;
  }
  .title-container {
    margin-top: 80px;
    margin-bottom: 150px;
  }
  
  .about-us-wrap {
    position: relative;
    min-height: 75vh;

    .elem-floor, .elem-flower, .elem-owner, .elem-lamp, .top-text, .bottom-text {
      position: absolute;
    }
    .elem-floor {
      bottom: 0;
      left: 0;
      width: 100%;
      img {
        width: 100%;
      }
    }
    .elem-flower {
      left: 10%;
      bottom: 10%;
    }
    .elem-owner {
      right: 10%;
      bottom: 0;
      max-width: 460px;
    }
    .elem-lamp {
      right: 0;
      bottom: 10%;
    }

    .top-text,.bottom-text {
      padding: 25px 35px;
      color: #F0F0F0;
      font-size: 12px;
      line-height: 2;
      left: 18%;
      width: 325px;
      bottom: 0;
      .title-block {
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 10px;
        position: relative;
      }
      p {
        position: relative;
      }
    }
    .top-text {
      border: 1px solid #313131;
      z-index: -1;
      padding-bottom: 25vw;
    }
    .bottom-text {
      background: url(../images/bg-body.png);
      bottom: 0;
      .elem-slice {
        position: absolute;
        top: -40px;
        left: 0;
        width: 100%;
      }
    }
    .link-more {
      font-weight: 700;
      font-size: 14px;
      color: $color_theme;
      .ico {
        margin-left: 10px;
        vertical-align: middle;
      }
      &:hover {
        color: #fff;
      }
    }
  }
}

.lets-meet-section {
  margin: 80px 0 20px;
  position: relative;
  border-bottom: 1px solid #313131;
  .image-team {
    position: relative;
    margin-top: -200px;
    text-align: right;
    img {
      display: block;
      margin-left: auto;
      max-width: 90%;
    }
  }
}

.our-production-section {
  position: relative;
  padding: 20px 0 40px;
  .elem-lamp {
    position: absolute;
    right: 5%;
    top: -20px;
  }
  .row {
    display: grid;
    grid-template-columns: 18% 27% 45%;
    align-items: flex-end;
  }
  .subtext-col {
    position: relative;
    z-index: 2;
    .title {
      font-weight: 700;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      color: #6A6A6A;
      margin-bottom: 20px;
      line-height: 1.8;
    }
  }
  .tree-col {
    position: relative;
    z-index: 1;
    img {
      position: relative;
      right: 0;
      width: 150%;
      max-width: none;
      max-height: none;
      left: -192px;
      bottom: 0;
    }
  }
  .text-col {
    position: relative;
    z-index: 2;
    .text {
      color: #f0f0f0;
      font-size: 24px;
      line-height: 1.6;
    }
  }
}


.statistics-section {
  padding: 24px 0;
  color: #F0F0F0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
  margin-bottom: 70px;
  position: relative;
  &::after,&::before {
    content: '';
    width: 0;
    height: 1px;
    background-color: #313131;
    position: absolute;
    transition: 2s;
  }
  &:after {
    bottom: 0;
    right: 0;
  }
  &:before {
    top: 0;
    left: 0;
  }
  &.active {
    &::after,&::before {
      width: 100%;
    }
  }
  .deco-line-circle {
    position: absolute;
    right: -9px;
    top: -170px;
    height: calc(200% + 170px);
    flex-direction: column;
    align-items: center;
  }
  .item-statistic {
    text-align: center;
    font-weight: 700;
    .viz {
      opacity: 1;
      transition: 1s;
    }
    .count {
      font-size: 64px;
    }
    .value {
      font-size: 12px;
    }
  }
  .title-container-wrap {
    border-left: 1px solid #313131;
    text-align: left;
    padding-left: 60px;
  }
  .title-container {
    margin: 0;
    font-size: 72px;
    line-height: 1.2;
  }
  .subtitle-container {
    color: #6A6A6A;
    font-size: 12px;
  }
}
.advantages-section {
  padding: 150px 0 170px;
  display: grid;
  grid-template-columns: 4fr 3fr 5fr;
  column-gap: 32px;
  position: relative;
  .deco-line-circle {
    position: absolute;
    left: 0;
    bottom: 45px;
    height: 190px;
    flex-direction: column;
    align-items: center;
  }
  img {
    width: 100%;
  }
  .advantage-desc {
    padding: 60px 0;
    font-size: 24px;
    line-height: 2;
  }

  .list-items-advantage {
    display: flex;
    column-gap: 30px;
    row-gap: 30px;
    flex-wrap: wrap;
    .item-advantage {
      width: 45%;
      position: relative;
      padding-left: 50px;
      .count {
        position: absolute;
        left: 0;
        top: 0;
        color: $color_theme;
        line-height: .8 !important;
        font: normal 40px 'New York', serif;
      }
      .title {
        font-size: 12px;
        font-weight: 700;
        color: #f0f0f0;
        margin-bottom: 10px;
        text-transform: uppercase;
      }
      .text {
        color: #6A6A6A;
        font-size: 12px;
        line-height: 1.4;
      }
    }
  }
  .center-image,.right-image {
    position: relative;
  }
  .deco,.deco-top-left,.deco-bottom-right {
    position: absolute;
  }
  .deco {
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .deco-top-left {
    top: 0;
    left: 0;
    width: 20%;
  }
  .deco-bottom-right {
    bottom: 0;
    right: 0;
    width: 20%;
  }
  .advantage-desc-sm {
    color: #6A6A6A;
    margin-top: 30px;
    font-size: 12px;
    line-height: 2;
  }
}

.reviews-section {
  display: grid;
  grid-template-columns: 43% 57%;
  justify-content: space-between;
  padding-bottom: 80px;
  position: relative;
  .title-container {
    margin-top: 0;
  }
  .text-content {
    .text {
      padding-right: 80px;
      color: #6A6A6A;
      a {
        color: #f0f0f0;
        &:hover {
          color: $color_theme;
        }
      }
    }
  }

  .dots-wrapper {
    right: 0;
  }
}

.slider-reviews-wrapper {
  
  width: 100%;
  position: relative;
  .slider-reviews {
    width: 100%;
    padding-right: 140px;
  }
  .item-slider-wrap {
    text-align: center;
    padding: 9px;
    border: 1px solid #323232;
    .user-thumb {
      width: 108px;
      height: 108px;
      border-radius: 50%;
      padding: 3px;
      overflow: hidden;
      border: 1px solid #323232;
      margin: 0 auto 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .title {
      color: #6A6A6A;
      margin-bottom: 10px;
      font-weight: 700;
    }
    .name {
      color: #F0F0F0;
      text-transform: uppercase;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .text {
      line-height: 2;
      color: #6A6A6A;
      height: 320px;
      overflow: hidden;
      position: relative;
      margin-bottom: 40px;
    }
    .link-more {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 50px;
      color: $color_theme;
      text-decoration: underline;

      &:hover {
        color: #fff;
        text-decoration: none;
      }
    }
  }
  .item-slider {
    padding: 30px;
    border: 1px solid #323232;
    position: relative;
  }
  .quote-start, .quote-end {
    position: absolute;
  }
  .quote-start {
    top: 15px;
    left: 15px;
  }
  .quote-end {
    bottom: 15px;
    right: 15px;
  }

  .slick-next, .slick-prev {
    top: 35%;
    &:before {
      content: none;
    }
    &:hover {
      path {
        stroke: $color_theme;
      }
    }
  }
  .slick-next {
    right: 0;
  }
  .slick-prev {
    left: auto;
    right: 85px;
  }
  .slick-slide {
    padding: 0 15px;
  }
}

.input-style {
  margin-bottom: 20px;
  position: relative;
  input:not([type='submit']),textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid #3E3E3E;
    padding: 15px 0;
    background: transparent;
    color: #fff;
    font-weight: 700;
    position: relative;
    z-index: 2;
    transition: .3s;
    &:focus {
      border-color: $color_theme;
      + span.label {
        font-size: 12px;
        top: -15px;
        padding-bottom: 50px;
        color: $color_theme;
      }
    }
  }
  .file-attachment {
    position: absolute;
    cursor: pointer;
    transition: .2s;
    top: -15px;
    right: 0;
    &:hover {
      opacity: .7;
    }
    input {
      width: 0;
      height: 0;
      opacity: 0;
    }
  }
  span.label {
    color: #727272;
    transition: .5s;
    display: block;
    padding-bottom: 40px;
    position: absolute;
    left: 0;
    top: -7px;
  }
}
.text-right {
  text-align: right !important;
}
.portfolio-section {
  padding: 80px 0;
  .title-container {
    padding-left: 10%;
  }
  .link-more-wrap {
    text-align: center;
    margin-top: 30px;
    .link-more {
      color: $color_theme;
      display: inline-block;
      padding: 5px;
      border-bottom: 2px solid $color_theme;
      font-weight: 700;
      font-size: 14px;
      text-transform: uppercase;
      position: relative;
      &:after {
        content: '';
        display: block;
        height: 0;
        background-color: #ff5c00;
        transition: .3s;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: -1;
      }
      &:hover {
        color: #fff;
        &:after {
          height: 100%;
        }
      }
      .ico {
        vertical-align: middle;
      }
    }
  }
}
.contact-section {
  opacity: 0;
  transform: translateY(150px);
  transition: 1.5s;
  .contact-form-wrapper {
    padding: 0 80px 0 30px;
  }
  .title-container-wrap {
    width: 80%;
    margin-left: auto;
    border-bottom: 1px solid #6A6A6A;
  }
  .title-contact-container {
    margin: 40px 0 30px;
  }

  .content-wrap {
    display: grid;
    grid-template-columns: 50% 40%;
    justify-content: space-between;
    padding: 20px 0;
    margin-bottom: 60px;
    .our-team {
      display: flex;
      align-items: center;
    }
    .text {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      line-height: 2;
      margin-bottom: 50px;
      a {
        color: $color_theme;
        &:hover {
          color: #fff;
        }
      }
    }
  }

  .btn-submit-wrap {
    margin-top: 70px;
  }
  
}
.btn-submit {
  display: inline-block;
  border: none;
  border-bottom: 2px solid $color_theme;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  padding: 10px 5px;
  color: $color_theme;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0px;
    background-color: $color_theme;
    transition: .4s;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:after {
      height: 100%;
    }
  }
  &.loading {
    border-color: transparent;
    animation: 1s linear infinite btnTextAnimatiion;
    &:hover {
      &:after {
        height: 2px;
      }
    }
    &:after {
      height: 2px;
      animation: 1s linear infinite btnLineAnimatiion;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  &[disabled] {
    cursor: default;
  }
  &.sent {
    border-color: #eee;
    color: #eee;
    &:after {
      content: none;
    }
  }
}
.map-container {
  height: 600px;
  background: #303030;
  position: relative;
  .contact-list-wrap {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 40px;
  }
  .slice-deco {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 2;
    transition: 1s;
  }
}
.contact-list {
  display: flex;
  column-gap: 32px;
  justify-content: flex-end;
  .item-contact {
    padding: 15px;
    background-color: #171717;
    color: #fff;
    width: 16%;
    .title {
      font-weight: 700;
      font-size: 12px;
      margin-bottom: 10px;
      &:after {
        content: '';
        width: 50%;
        height: 1px;
        display: inline-block;
        background-color: #fff;
        margin-left: 7px;
        vertical-align: middle;
      }
    }
    p {
      margin: 5px 0;
      color: #D3D3D3;
    }
    a {
      color: #D3D3D3;
      &:hover {
        color: $color_theme;
      }
    }
  }
}
#custom-map {
  position: absolute;
  width: 100%;
  height: 100%;
}
div#custom-map a,
div#custom-map button,
div.gm-bundled-control div.gmnoprint, div.gm-svpc {
  display: none !important;
}

.content-contact {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 50px 0;
  column-gap: 200px;
  .form-wrap {
    margin-top: 40px;
  }
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 32px;
  }
}

 .main-title-project {
  font: normal 95px NewYork;
  width: 50%;
 }

footer.footer {
  padding: 20px 0;
  .copy-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .copy {
    color: #6A6A6A;
    font-size: 12px;
  }
  .social-list {
    a {
      color: #F0F0F0;
      font-size: 12px;
      &:hover {
        color: $color_theme;
      }
    }
  }
}
