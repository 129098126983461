@keyframes dreamSpot {
    0% {
        filter: drop-shadow(0px -4px 2px black);
    }
    75% {
        filter: drop-shadow(0px 4px 2px black);
    }
    100% {
        filter: drop-shadow(0px -4px 2px black);
    }
}
@keyframes switchAnimatiion {
    0% {
        background: radial-gradient(circle 87px at 45% 54%,#ffec97,transparent);
    }
    75% {
        background: radial-gradient(circle 87px at 45% 54%,#fff7d1,transparent);
    }
    100% {
        background: radial-gradient(circle 87px at 45% 54%,#e6d173,transparent);
    }
}
@keyframes btnLineAnimatiion {
    0% {
        width: 0;
        background: #fff;
    }
    50% {
        width: 100%;
        background: #ff5c00;
    }
    100% {
        width: 0;
        background: #fff;
    }
}
@keyframes btnTextAnimatiion {
    0% {
        color: #fff;
    }
    50% {
        color: #ff5c00;
    }
    100% {
        color: #fff;
    }
}