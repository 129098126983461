@font-face {
  font-family: NewYork;
  src: url(../fonts/NewYork.ttf);
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: GothamPro;
  src: url(../fonts/GothamPro/GothamPro-Light.ttf);
  src: url(../fonts/GothamPro/GothamPro-Light.woff) format("woff"), url(../fonts/GothamPro/GothamPro-Light.eot) format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 400
}
@font-face {
  font-family: GothamPro;
  src: url(../fonts/GothamPro/GothamPro-Medium.ttf);
  src: url(../fonts/GothamPro/GothamPro-Medium.woff) format("woff"), url(../fonts/GothamPro/GothamPro-Medium.eot) format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 500
}
@font-face {
  font-family: GothamPro;
  src: url(../fonts/GothamPro/GothamPro-Bold.ttf);
  src: url(../fonts/GothamPro/GothamPro-Bold.woff) format("woff"), url(../fonts/GothamPro/GothamPro-Bold.eot) format("truetype");
  font-style: normal;
  font-display: swap;
  font-weight: 700
}